<template>
    <div class="published">
        <memod-list :list-endpoint="listEndpoint" :no-memos-found-message="noMemosFoundMessage" @delete-memo="(memoId) => $emit('delete-memo', memoId)" />
    </div>
</template>

<script>
export default {
    name: "Published",
    components: {
        MemodList: () => import(/* webpackChunkName: "memod-list" */ "@/components/organisms/memod-list.vue")
    },
    props: {
        listEndpoint: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            noMemosFoundMessage: {
                icon: require("@assets/img/icons/feed.svg"),
                title: "No Memos published",
                text: "No published Memos so far... Click “Create” and share your knowledge with the world!"
            }
        }
    }
}
</script>
